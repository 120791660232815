import HomePageContainer from 'commonModule/containers/pages/HomePageContainer';
import SignIn from 'authModule/containers/pages/SignInPageContainer';
import SignUp from 'authModule/containers/pages/SignUpPageContainer';
import Profile from 'userModule/containers/pages/ProfilePageContainer';
import NoMatch from 'commonModule/containers/pages/NoMatchPageContainer';

// Category
import AdminAddCategoryPageContainer from 'categoryModule/containers/AdminAddCategoryPageContainer';
import AdminUpdateCategoryPageContainer from 'categoryModule/containers/AdminUpdateCategoryPageContainer';
import AdminCategoryListPageContainer from 'categoryModule/containers/AdminCategoryListPageContainer';

// Symptom
import AdminAddSymptomPageContainer from 'symptomModule/containers/AdminAddSymptomPageContainer';
import AdminSymptomListPageContainer from 'symptomModule/containers/AdminSymptomListPageContainer';
import AdminUpdateSymptomPageContainer from 'symptomModule/containers/AdminUpdateSymptomPageContainer';
import SymptomPageContainer from 'symptomModule/containers/SymptomPageContainer';

// Complication
import AdminAddComplicationPageContainer from 'complicationModule/containers/AdminAddComplicationPageContainer';
import AdminComplicationListPageContainer from 'complicationModule/containers/AdminComplicationListPageContainer';
import AdminUpdateComplicationPageContainer from 'complicationModule/containers/AdminUpdateComplicationPageContainer';
import ComplicationPageContainer from 'complicationModule/containers/ComplicationPageContainer';

// Diagnosis
import AdminAddDiagnosisPageContainer from 'diagnosisModule/containers/AdminAddDiagnosisPageContainer';
import AdminDiagnosisListPageContainer from 'diagnosisModule/containers/AdminDiagnosisListPageContainer';
import AdminUpdateDiagnosisPageContainer from 'diagnosisModule/containers/AdminUpdateDiagnosisPageContainer';
import DiagnosisPageContainer from 'diagnosisModule/containers/DiagnosisPageContainer';

// Treatment
import AdminAddTreatmentPageContainer from 'treatmentModule/containers/AdminAddTreatmentPageContainer';
import AdminTreatmentListPageContainer from 'treatmentModule/containers/AdminTreatmentListPageContainer';
import AdminUpdateTreatmentPageContainer from 'treatmentModule/containers/AdminUpdateTreatmentPageContainer';
import TreatmentPageContainer from 'treatmentModule/containers/TreatmentPageContainer';

// Prevention
import AdminAddPreventionPageContainer from 'preventionModule/containers/AdminAddPreventionPageContainer';
import AdminPreventionListPageContainer from 'preventionModule/containers/AdminPreventionListPageContainer';
import AdminUpdatePreventionPageContainer from 'preventionModule/containers/AdminUpdatePreventionPageContainer';
import PreventionPageContainer from 'preventionModule/containers/PreventionPageContainer';

// Cause
import AdminAddCausePageContainer from 'causeModule/containers/AdminAddCausePageContainer';
import AdminCauseListPageContainer from 'causeModule/containers/AdminCauseListPageContainer';
import AdminUpdateCausePageContainer from 'causeModule/containers/AdminUpdateCausePageContainer';
import CausePageContainer from 'causeModule/containers/CausePageContainer';

// Disease
import DiseasePageContainer from 'diseaseModule/containers/DiseasePageContainer';
import AdminDiseaseListPageContainer from 'diseaseModule/containers/AdminDiseaseListPageContainer';
import AdminAddDiseasePageContainer from 'diseaseModule/containers/AdminAddDiseasePageContainer';
import AdminUpdateDiseasePageContainer from 'diseaseModule/containers/AdminUpdateDiseasePageContainer';

// Dashboard
import AdminDashboardPageContainer from 'commonModule/containers/pages/AdminDashboardPageContainer';

// StaticPages
import AdminAddStaticPageContainer from 'staticPageModule/containers/AdminAddStaticPageContainer';
import StaticPageContainer from 'staticPageModule/containers/StaticPageContainer';
import AdminUpdateStaticPageContainer from 'staticPageModule/containers/AdminUpdateStaticPageContainer';
import AdminStaticPageListContainer from 'staticPageModule/containers/AdminStaticPageListContainer';

// Post
import PostPageContainer from 'postModule/containers/PostPageContainer';
import AddPostPageContainer from 'postModule/containers/AddPostPageContainer';
import SearchResultsContainer from 'diseaseModule/containers/SearchResultsContainer';
import modelEnums from 'commonModule/infrastructure/enums/modelEnums';
import { SLUGS } from 'coreModule/infrastructure/multiLanguageSlugs';

export default [
  {
    path: '/',
    component: HomePageContainer,
    exact: true,
  },
  // AUTH ROUTES:
  {
    path: '/sign-up',
    component: SignUp,
    exact: true,
  },
  {
    path: `/${SLUGS[process.env.RAZZLE_LANGUAGE_CODE].LOG_IN_SLUG}`,
    component: SignIn,
    exact: true,
  },
  {
    path: `/${SLUGS[process.env.RAZZLE_LANGUAGE_CODE].PROFILE_SLUG}`,
    component: Profile,
    exact: true,
  },
  {
    // ADMIN CRUD ROUTES:
    path: '/admin/add-static-page',
    component: AdminAddStaticPageContainer,
    exact: true,
  },
  {
    path: '/admin/static-page/:slug',
    component: AdminUpdateStaticPageContainer,
    exact: true,
  },
  {
    path: '/admin/static-page-list',
    component: AdminStaticPageListContainer,
    exact: true,
  },
  {
    path: '/admin/add-category',
    component: AdminAddCategoryPageContainer,
    exact: true,
  },
  {
    path: `/admin/${process.env.RAZZLE_CATEGORY_SLUG__EN}/:slug`,
    component: AdminUpdateCategoryPageContainer,
    exact: true,
  },
  {
    path: '/admin/category-list',
    component: AdminCategoryListPageContainer,
    exact: true,
  },
  {
    // symptom
    path: '/admin/add-symptom',
    component: AdminAddSymptomPageContainer,
    exact: true,
  },
  {
    path: '/admin/symptom-list',
    component: AdminSymptomListPageContainer,
    exact: true,
  },
  {
    path: `/admin/${process.env.RAZZLE_SYMPTOM_SLUG__EN}/:slug`,
    component: AdminUpdateSymptomPageContainer,
  },
  {
    // complication
    path: '/admin/add-complication',
    component: AdminAddComplicationPageContainer,
    exact: true,
  },
  {
    path: '/admin/complication-list',
    component: AdminComplicationListPageContainer,
    exact: true,
  },
  {
    path: `/admin/${process.env.RAZZLE_COMPLICATION_SLUG}/:slug`,
    component: AdminUpdateComplicationPageContainer,
  },
  {
    // cause
    path: '/admin/add-cause',
    component: AdminAddCausePageContainer,
    exact: true,
  },
  {
    path: '/admin/cause-list',
    component: AdminCauseListPageContainer,
    exact: true,
  },
  {
    path: `/admin/${process.env.RAZZLE_CAUSE_SLUG__EN}/:slug`,
    component: AdminUpdateCausePageContainer,
  },
  {
    // Prevention
    path: '/admin/add-prevention',
    component: AdminAddPreventionPageContainer,
    exact: true,
  },
  {
    path: '/admin/prevention-list',
    component: AdminPreventionListPageContainer,
    exact: true,
  },
  {
    path: `/admin/${process.env.RAZZLE_PREVENTION_SLUG__EN}/:slug`,
    component: AdminUpdatePreventionPageContainer,
  },
  {
    // treatment
    path: '/admin/add-treatment',
    component: AdminAddTreatmentPageContainer,
    exact: true,
  },
  {
    path: '/admin/treatment-list',
    component: AdminTreatmentListPageContainer,
    exact: true,
  },
  {
    path: `/admin/${process.env.RAZZLE_TREATMENT_SLUG__EN}/:slug`,
    component: AdminUpdateTreatmentPageContainer,
  },
  {
    // diagnosis
    path: '/admin/add-diagnosis',
    component: AdminAddDiagnosisPageContainer,
    exact: true,
  },
  {
    path: '/admin/diagnosis-list',
    component: AdminDiagnosisListPageContainer,
    exact: true,
  },
  {
    path: `/admin/${process.env.RAZZLE_DIAGNOSIS_SLUG__EN}/:slug`,
    component: AdminUpdateDiagnosisPageContainer,
  },
  {
    // disease
    path: '/admin/add-disease',
    component: AdminAddDiseasePageContainer,
    exact: true,
  },
  {
    path: '/admin/disease-list',
    component: AdminDiseaseListPageContainer,
    exact: true,
  },
  {
    path: `/admin/${process.env.RAZZLE_DISEASE_SLUG__EN}/:slug`,
    component: AdminUpdateDiseasePageContainer,
  },
  {
    // admin generic
    path: '/admin',
    component: AdminDashboardPageContainer,
    exact: true,
  },
  {
    // CONTENT ROUTES:
    path: `/${SLUGS[process.env.RAZZLE_LANGUAGE_CODE][modelEnums.Disease]}/:slug`,
    component: DiseasePageContainer,
  },
  {
    path: `/${SLUGS[process.env.RAZZLE_LANGUAGE_CODE][modelEnums.Post]}/:id/:targetModel/:postTitle`,
    component: PostPageContainer,
  },
  {
    path: `/${SLUGS[process.env.RAZZLE_LANGUAGE_CODE].ADD_POST_SLUG}`,
    component: AddPostPageContainer,
  },
  {
    path: `/${SLUGS[process.env.RAZZLE_LANGUAGE_CODE].SEARCH_RESULTS_SLUG}`,
    component: SearchResultsContainer,
  },
  {
    path: `/${SLUGS[process.env.RAZZLE_LANGUAGE_CODE][modelEnums.Symptom]}/:slug`,
    component: SymptomPageContainer,
  },
  {
    path: `/${SLUGS[process.env.RAZZLE_LANGUAGE_CODE][modelEnums.Complication]}/:slug`,
    component: ComplicationPageContainer,
  },
  {
    path: `/${SLUGS[process.env.RAZZLE_LANGUAGE_CODE][modelEnums.Treatment]}/:slug`,
    component: TreatmentPageContainer,
  },
  {
    path: `/${SLUGS[process.env.RAZZLE_LANGUAGE_CODE][modelEnums.Cause]}/:slug`,
    component: CausePageContainer,
  },
  {
    path: `/${SLUGS[process.env.RAZZLE_LANGUAGE_CODE][modelEnums.Diagnosis]}/:slug`,
    component: DiagnosisPageContainer,
  },
  {
    path: `/${SLUGS[process.env.RAZZLE_LANGUAGE_CODE][modelEnums.Prevention]}/:slug`,
    component: PreventionPageContainer,
  },
  {
    path: `/${SLUGS[process.env.RAZZLE_LANGUAGE_CODE].PRIVACY_POLICY_SLUG}`,
    component: StaticPageContainer,
    exact: true,
  },
  {
    path: `/${SLUGS[process.env.RAZZLE_LANGUAGE_CODE].CONTACT_SLUG}`,
    component: StaticPageContainer,
    exact: true,
  },
  {
    // 404 ROUTE:
    component: NoMatch,
  },
  {
    path: `/${process.env.RAZZLE_NOT_FOUND_SLUG}`,
    component: NoMatch,
    exact: true,
  },
];
