import React from 'react';
import { connect } from 'react-redux';

import {
  GET_SINGLE_POST_FAIL,
  GET_SINGLE_POST_SUCCESS,
  getSinglePostAction,
} from 'postModule/redux/modules/getSinglePostModule';
import getSlug from 'commonModule/helpers/getSlug';
import fetchInitialData from 'commonModule/helpers/fetchInitialData';
import { getSinglePostCall } from 'postModule/infrastructure/getSinglePostLogic';
import PostPage from 'postModule/components/pages/PostPage';
import { addSubPostResetAction } from 'postModule/redux/modules/addSubPostModule';
import { SLUGS } from 'coreModule/infrastructure/multiLanguageSlugs';
import modelEnums from 'commonModule/infrastructure/enums/modelEnums';

const PostPageContainer = props => <PostPage {...props} />;

const mapStateToProps = ({ post: { getSinglePostReducer }, post: { addPostReducer, addSubPostReducer } }) => ({
  getSinglePostReducer,
  addPostReducer,
  addSubPostReducer,
});

const mapDispatchToProps = { getSinglePostAction, addSubPostResetAction };
PostPageContainer.getInitialProps = async ({
  req, res, match, history, location, store, ...ctx
}) => Promise.all([
  await fetchInitialData(
    store,
    () => getSinglePostCall({ slug: getSlug(match.url, SLUGS[process.env.RAZZLE_LANGUAGE_CODE][modelEnums.Post]) }),
    GET_SINGLE_POST_SUCCESS,
    GET_SINGLE_POST_FAIL,
    res,
  ),
]);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PostPageContainer);
