import redirect404 from 'commonModule/helpers/redirect404';

export default async (store, apiCall, successResponseDispatch, failResponseDispatch, res) => {
  const { response, error } = await apiCall();
  if (response) {
    await store.dispatch({ type: successResponseDispatch, data: response.data });
  } else {
    redirect404(res, error);
    await store.dispatch({ type: failResponseDispatch, data: error });
  }
};
