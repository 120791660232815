import React from 'react';
import { connect } from 'react-redux';

import CausePage from 'causeModule/components/pages/CausePage';
import {
  GET_SINGLE_CAUSE_FAIL,
  GET_SINGLE_CAUSE_SUCCESS,
  getSingleCauseAction,
} from 'causeModule/redux/modules/getSingleCauseModule';
import getSlug from 'commonModule/helpers/getSlug';
import fetchInitialData from 'commonModule/helpers/fetchInitialData';
import { getSingleCauseCall } from 'causeModule/infrastructure/getSingleCauseLogic';
import { addPostResetAction } from 'postModule/redux/modules/addPostModule';
import { addSubPostResetAction } from 'postModule/redux/modules/addSubPostModule';
import { getModelPostListCall } from 'postModule/infrastructure/getModelPostListLogic';
import modelEnums from 'commonModule/infrastructure/enums/modelEnums';
import { GET_MODEL_POST_LIST_FAIL, GET_MODEL_POST_LIST_SUCCESS } from 'postModule/redux/modules/getModelPostListModule';

const CausePageContainer = props => <CausePage {...props} />;

const mapStateToProps = ({ cause: { getSingleCauseReducer }, post: { addPostReducer, addSubPostReducer, getModelPostListReducer } }) => ({
  getSingleCauseReducer,
  addPostReducer,
  addSubPostReducer,
  getModelPostListReducer,
});

const mapDispatchToProps = { getSingleCauseAction, addPostResetAction, addSubPostResetAction };

CausePageContainer.getInitialProps = async ({
  req, res, match, history, location, store, ...ctx
}) => await Promise.all([
  await fetchInitialData(
    store,
    () => getSingleCauseCall({ slug: getSlug(match.url) }),
    GET_SINGLE_CAUSE_SUCCESS,
    GET_SINGLE_CAUSE_FAIL,
    res,
  ),
  await fetchInitialData(
    store,
    () => getModelPostListCall({ slug: getSlug(match.url), modelEnum: modelEnums.Cause }),
    GET_MODEL_POST_LIST_SUCCESS,
    GET_MODEL_POST_LIST_FAIL,
    res,
  ),
]);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CausePageContainer);
