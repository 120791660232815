import React, { useState } from 'react';
import { Link, NavLink as RRNavLink } from 'react-router-dom';
import UserRoleEnum from 'authModule/infrastructure/enums/userRoleEnum';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  Button,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { SLUGS } from 'coreModule/infrastructure/multiLanguageSlugs';

const Header = ({ signOutUserAction, isAuth, userDetails }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const isAdmin = userDetails.role === UserRoleEnum.ADMIN;
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Navbar color="light" light expand="md">
      <Link className="font-weight-bold navbar-brand" to="/">
        {process.env.RAZZLE_APP_NAME}
      </Link>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ml-auto" navbar>
          <NavItem key={process.env.RAZZLE_ADD_POST_SLUG}>
            <NavLink className="btn btn-primary text-white" to={`/${SLUGS[process.env.RAZZLE_LANGUAGE_CODE].ADD_POST_SLUG}`} tag={RRNavLink}>
              {t('header.askQuestion')}
            </NavLink>
          </NavItem>
          {isAuth && (
            <NavItem key={SLUGS[process.env.RAZZLE_LANGUAGE_CODE].PROFILE_SLUG}>
              <NavLink to={`/${SLUGS[process.env.RAZZLE_LANGUAGE_CODE].PROFILE_SLUG}`} tag={RRNavLink}>{t('header.myProfile')}</NavLink>
            </NavItem>
          )}
          {(isAuth && isAdmin) && (
            <NavItem key="admin">
              <NavLink to="/admin" tag={RRNavLink}>{t('header.adminDashboard')}</NavLink>
            </NavItem>
          )}
          {/*					{!isAuth && <NavItem key={SLUGS[process.env.RAZZLE_LANGUAGE_CODE].SIGN_UP_SLUG}>
						<NavLink to={`/${SLUGS[process.env.RAZZLE_LANGUAGE_CODE].SIGN_UP_SLUG}`} tag={RRNavLink}>{t('header.signUp')}</NavLink>
					</NavItem>} */}
          {!isAuth && (
            <NavItem key={SLUGS[process.env.RAZZLE_LANGUAGE_CODE].LOG_IN_SLUG}>
              <NavLink to={`/${SLUGS[process.env.RAZZLE_LANGUAGE_CODE].LOG_IN_SLUG}`} tag={RRNavLink}>{t('header.logIn')}</NavLink>
            </NavItem>
          )}
          {isAuth && (
            <NavItem key="sign-out">
              <Button color="link" className="nav-link" onClick={signOutUserAction}>{t('header.logOut')}</Button>
            </NavItem>
          )}
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default Header;
