import React from 'react';
import { connect } from 'react-redux';

import SymptomPage from 'symptomModule/components/pages/SymptomPage';
import {
  GET_SINGLE_SYMPTOM_FAIL,
  GET_SINGLE_SYMPTOM_SUCCESS,
  getSingleSymptomAction,
} from 'symptomModule/redux/modules/getSingleSymptomModule';
import {
  GET_MODEL_POST_LIST_FAIL,
  GET_MODEL_POST_LIST_SUCCESS,
} from 'postModule/redux/modules/getModelPostListModule';
import getSlug from 'commonModule/helpers/getSlug';
import fetchInitialData from 'commonModule/helpers/fetchInitialData';
import { getSingleSymptomCall } from 'symptomModule/infrastructure/getSingleSymptomLogic';
import { addPostResetAction } from 'postModule/redux/modules/addPostModule';
import { addSubPostResetAction } from 'postModule/redux/modules/addSubPostModule';
import { getModelPostListCall } from 'postModule/infrastructure/getModelPostListLogic';
import modelEnums from 'commonModule/infrastructure/enums/modelEnums';

const SymptomPageContainer = props => <SymptomPage {...props} />;

const mapStateToProps = ({ symptom: { getSingleSymptomReducer }, post: { addPostReducer, addSubPostReducer, getModelPostListReducer } }) => ({
  getSingleSymptomReducer,
  addPostReducer,
  addSubPostReducer,
  getModelPostListReducer,
});

const mapDispatchToProps = { getSingleSymptomAction, addPostResetAction, addSubPostResetAction };

SymptomPageContainer.getInitialProps = async ({
  req, res, match, history, location, store, ...ctx
}) => await Promise.all([
  await fetchInitialData(
    store,
    () => getSingleSymptomCall({ slug: getSlug(match.url) }),
    GET_SINGLE_SYMPTOM_SUCCESS,
    GET_SINGLE_SYMPTOM_FAIL,
    res,
  ),
  await fetchInitialData(
    store,
    () => getModelPostListCall({ slug: getSlug(match.url), modelEnum: modelEnums.Symptom }),
    GET_MODEL_POST_LIST_SUCCESS,
    GET_MODEL_POST_LIST_FAIL,
    res,
  ),
]);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SymptomPageContainer);
