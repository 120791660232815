import React from 'react';
import { connect } from 'react-redux';

import HomePage from 'commonModule/components/pages/HomePage';
import fetchInitialData from 'commonModule/helpers/fetchInitialData';
import {
  GET_SYMPTOM_LIST_FAIL,
  GET_SYMPTOM_LIST_SUCCESS,
} from 'symptomModule/redux/modules/getSymptomListModule';
import {
  GET_POST_LIST_FAIL,
  GET_POST_LIST_SUCCESS,
} from 'postModule/redux/modules/getPostListModule';
import { getSymptomListCall } from 'symptomModule/infrastructure/getSymptomListLogic';
import { getPostListCall } from 'postModule/infrastructure/getPostListLogic';

const HomePageContainer = (props) => <HomePage {...props} />;

const mapStateToProps = ({ post: { getPostListReducer }, symptom: { getSymptomListReducer } }) => ({
  postListData: getPostListReducer?.data,
  symptomListData: getSymptomListReducer?.data,
});

const mapDispatchToProps = {};

HomePageContainer.getInitialProps = async ({
  req, res, match, history, location, store, ...ctx
}) => Promise.all([
  fetchInitialData(
    store,
    () => getPostListCall({}),
    GET_POST_LIST_SUCCESS,
    GET_POST_LIST_FAIL,
    res,
  ),
  fetchInitialData(
    store,
    () => getSymptomListCall({}),
    GET_SYMPTOM_LIST_SUCCESS,
    GET_SYMPTOM_LIST_FAIL,
    res,
  ),

]);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HomePageContainer);
