import React from 'react';
import { connect } from 'react-redux';

import DiagnosisPage from 'diagnosisModule/components/pages/DiagnosisPage';
import {
  GET_SINGLE_DIAGNOSIS_FAIL,
  GET_SINGLE_DIAGNOSIS_SUCCESS,
  getSingleDiagnosisAction,
} from 'diagnosisModule/redux/modules/getSingleDiagnosisModule';
import getSlug from 'commonModule/helpers/getSlug';
import fetchInitialData from 'commonModule/helpers/fetchInitialData';
import { getSingleDiagnosisCall } from 'diagnosisModule/infrastructure/getSingleDiagnosisLogic';
import { addPostResetAction } from 'postModule/redux/modules/addPostModule';
import { addSubPostResetAction } from 'postModule/redux/modules/addSubPostModule';
import { getModelPostListCall } from 'postModule/infrastructure/getModelPostListLogic';
import modelEnums from 'commonModule/infrastructure/enums/modelEnums';
import { GET_MODEL_POST_LIST_FAIL, GET_MODEL_POST_LIST_SUCCESS } from 'postModule/redux/modules/getModelPostListModule';

const DiagnosisPageContainer = props => <DiagnosisPage {...props} />;

const mapStateToProps = ({ diagnosis: { getSingleDiagnosisReducer }, post: { addPostReducer, addSubPostReducer, getModelPostListReducer } }) => ({
  getSingleDiagnosisReducer,
  addPostReducer,
  addSubPostReducer,
  getModelPostListReducer,
});

const mapDispatchToProps = { getSingleDiagnosisAction, addPostResetAction, addSubPostResetAction };

DiagnosisPageContainer.getInitialProps = async ({
  req, res, match, history, location, store, ...ctx
}) => await Promise.all([
  await fetchInitialData(
    store,
    () => getSingleDiagnosisCall({ slug: getSlug(match.url) }),
    GET_SINGLE_DIAGNOSIS_SUCCESS,
    GET_SINGLE_DIAGNOSIS_FAIL,
    res,
  ),
  await fetchInitialData(
    store,
    () => getModelPostListCall({ slug: getSlug(match.url), modelEnum: modelEnums.Diagnosis }),
    GET_MODEL_POST_LIST_SUCCESS,
    GET_MODEL_POST_LIST_FAIL,
    res,
  ),
]);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DiagnosisPageContainer);
