import React from 'react';
import { Spinner } from 'reactstrap';

import './styles.scss';

const Badge = ({ isFullscreen }) => {
  const loader = <Spinner className="loader" color="primary" type="grow" />;
  const renderFullScreen = () => {
    if (isFullscreen) {
      return (
        <div className="fullscreen">
          {loader}
        </div>
      );
    }

    return loader;
  };


  return (
    renderFullScreen()
  );
};

export default Badge;
