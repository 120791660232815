import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Header from 'commonModule/components/Header';
import { signOutUserAction } from 'authModule/redux/modules/signInUserModule';


const HeaderContainer = props => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet
        titleTemplate={`%s | ${process.env.RAZZLE_APP_NAME}`}
        defaultTitle={t('meta.description')}
      />
      <meta name="google-site-verification" content="GRJDxBzEYbxALExKwrClAslsClOHkCViS0MhVEln0Oo" />
      <Header {...props} />
    </>
  );
};

const mapStateToProps = ({ auth: { signInUserReducer: { isAuth, userDetails } } }) => ({
  isAuth,
  userDetails,
});

const mapDispatchToProps = {
  signOutUserAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeaderContainer);

