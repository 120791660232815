import 'bootstrap/dist/css/bootstrap.min.css';
import 'commonModule/theme/index.scss';
import './i18n';
import React, { Suspense } from 'react';
import { hydrate } from 'react-dom';
import { Router } from 'react-router-dom';
import { useSSR } from 'react-i18next';
import { ensureReady, After, getSerializedData } from '@jaredpalmer/after';
import routes from 'coreModule/routes';
import { Provider } from 'react-redux';
import history from 'commonModule/helpers/history';
import { initializeStore } from 'coreModule/redux/store';
import { isAuth } from 'authModule/infrastructure/signInUserLogic';
import PopupsListContainer from 'commonModule/containers/PopupsListContainer';
import Loader from 'commonModule/components/Loader';


const authData = isAuth();

const preLoadedState = getSerializedData('preloaded_state');
const auth = {
  ...preLoadedState.auth,
  signInUserReducer: {
    ...preLoadedState.auth.signInUserReducer,
    isAuth: !!authData,
    userDetails: authData || {},
  },
};

const store = initializeStore({ ...preLoadedState, auth });

const BaseApp = ({ data }) => {
  useSSR(window.initialI18nStore, window.initialLanguage);
  return (
    <Suspense fallback={<Loader isFullscreen />}>
      <Provider store={store}>
        <PopupsListContainer />
        <Router history={history}>
          <After data={data} routes={routes} store={store} />
        </Router>
      </Provider>
    </Suspense>
  );
};

function renderApp() {
  ensureReady(routes).then(data => hydrate(
    <BaseApp data={data} />,
    document.getElementById('root'),
  ));
}

renderApp();

if (module.hot) {
  module.hot.accept('coreModule/routes', renderApp);
}
