import React from 'react';
import { connect } from 'react-redux';

import DiseasePage from 'diseaseModule/components/pages/DiseasePage';
import {
  GET_SINGLE_DISEASE_FAIL,
  GET_SINGLE_DISEASE_SUCCESS,
  getSingleDiseaseAction,
} from 'diseaseModule/redux/modules/getSingleDiseaseModule';
import {
  GET_MODEL_POST_LIST_FAIL,
  GET_MODEL_POST_LIST_SUCCESS,
} from 'postModule/redux/modules/getModelPostListModule';
import getSlug from 'commonModule/helpers/getSlug';
import modelEnums from 'commonModule/infrastructure/enums/modelEnums';
import fetchInitialData from 'commonModule/helpers/fetchInitialData';
import { getSingleDiseaseCall } from 'diseaseModule/infrastructure/getSingleDiseaseLogic';
import { getModelPostListCall } from 'postModule/infrastructure/getModelPostListLogic';
import { addPostResetAction } from 'postModule/redux/modules/addPostModule';
import { addSubPostResetAction } from 'postModule/redux/modules/addSubPostModule';

const DiseasePageContainer = props => (
  <DiseasePage {...props} />
);

const mapStateToProps = ({ disease: { getSingleDiseaseReducer }, post: { addPostReducer, addSubPostReducer, getModelPostListReducer } }) => ({
  getSingleDiseaseReducer,
  addPostReducer,
  addSubPostReducer,
  getModelPostListReducer,
});

const mapDispatchToProps = { getSingleDiseaseAction, addPostResetAction, addSubPostResetAction };

DiseasePageContainer.getInitialProps = async ({
  req, res, match, history, location, store, ...ctx
}) => await Promise.all([
  await fetchInitialData(
    store,
    () => getSingleDiseaseCall({ slug: getSlug(match.url) }),
    GET_SINGLE_DISEASE_SUCCESS,
    GET_SINGLE_DISEASE_FAIL,
    res,
  ),
  await fetchInitialData(
    store,
    () => getModelPostListCall({ slug: getSlug(match.url), modelEnum: modelEnums.Disease }),
    GET_MODEL_POST_LIST_SUCCESS,
    GET_MODEL_POST_LIST_FAIL,
    res,
  ),
]);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DiseasePageContainer);
