import { combineReducers } from 'redux';

import popupsReducer from './popupsModule';


const common = combineReducers({
  popupsReducer,
});

export default common;
